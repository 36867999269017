<template>
  <div class="interfaceContainer" v-loading="loading">
    <!-- 面包屑 -->
    <div class="bread">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item >产品中心</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <div class="main" >
      <div class="title" v-if="!isMobileFlag">主要 <span style="font-size:20px; line-height:28px;">·</span> 共性 <span style="font-size:20px;line-height:28px;">·</span> 核心技术 </div>
      <div class="titleMsg" v-if="!isMobileFlag">
        <div class="titleDiv">
          <div class="titleMin">青稞RISC-V微处理器</div>
        </div>
        <div class="titleDiv">
          <div class="titleMin">USB控制器+PHY</div>
        </div>
        <div class="titleDiv">
          <div class="titleMin">以太网控制器+PHY</div>
        </div>
        <div class="titleDiv">
          <div class="titleMin">蓝牙射频+基带</div>
          </div>
        <div class="titleDiv">
          <div class="titleMin">协议栈</div>
        </div>
      </div>
      <div class="lectotype module" style="margin-top: 30px">
        <div class="lectotypeTitle moduleTitle" style="margin-bottom: 10px;">接口芯片</div>
        <div class="lectotypeContent moduleContent">
          围绕典型应用场景，通过垂直贯通、交叉组合、软硬件协同等方式，沁恒提供4大类百余款芯片产品，覆盖物理层PHY收发器、控制器、网络协议栈、桥接转换透传芯片等多个层级的品类。这些高度优化的接口芯片与上位机的操作系统内核驱动程序、通讯连接库和APP应用工具相互配合，屏蔽了各个专业领域的底层技术细节，通过虚拟化等技术实现上下互通、设备跨平台移动互联和应用平移，强化了连接和联网能力，提升了终端产品的附加值。
        </div>
        <div class="interfaceMsg" v-if="!changFlag">
            <div class="interfaceTop">
             <!--  <img style="height: 100px;margin-bottom: 20px;" src="../../assets/image/CH335.png" alt=""/> -->
              <div class="interfaceDiv">
                <div class="interfaceTitle" @click="clickTab(interfaceArr_1.id)">{{interfaceArr_1.name}}</div>
                <div v-if="interfaceArr_1.childCategoriesVOList">
                  <div v-for="num in 3">
                    <div class="interfaceTab" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[num-1].name)">{{interfaceArr_1.childCategoriesVOList[num-1].name}}</div>
                  </div>
                  <div style="display: flex;justify-content: space-between;">
                    <div class="interfaceTab" style="width: 100%;" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[3].name)">{{interfaceArr_1.childCategoriesVOList[3].name}}</div>
                    <div class="interfaceTab1" style="width: 60%;" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[4].name)">{{interfaceArr_1.childCategoriesVOList[4].name}}</div>
                  </div>
                  <div style="display: flex;justify-content: space-between;">
                    <div class="interfaceTab" style="width: 100%;" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[5].name)">{{interfaceArr_1.childCategoriesVOList[5].name}}</div>
                    <div class="interfaceTab1" style="width: 60%;" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[6].name)">{{interfaceArr_1.childCategoriesVOList[6].name}}</div>
                  </div>
                  <!-- <div style="display: flex;justify-content: space-between;">
                    <div class="interfaceTab" style="width: 100%;" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[7].name)">{{interfaceArr_1.childCategoriesVOList[7].name}}</div>
                    <div class="interfaceTab1" style="width: 60%;" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[8].name)">{{interfaceArr_1.childCategoriesVOList[8].name}}</div>
                  </div> -->
                  <div>
                    <div class="interfaceTab" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[7].name)">{{interfaceArr_1.childCategoriesVOList[7].name}}</div>
                    <div class="interfaceTab" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[8].name)">{{interfaceArr_1.childCategoriesVOList[8].name}}</div>
                    <div class="interfaceTab" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[9].name)">{{interfaceArr_1.childCategoriesVOList[9].name}}</div>
                  </div>
                </div>
                <div v-else>
                  <div v-for="num in maxLength">
                    <div class="interfaceTab_2"></div>
                  </div>
                </div>
              </div>
              <div class="interfaceTab_1" >
                <div style="cursor: pointer;" @click="openSite(5)">更多USB产品>></div>
              </div>
            </div>
            <div class="interfaceTop">
              <!-- <img style="height: 100px;margin-bottom: 20px;" src="../../assets/image/CH583.png" alt=""/> -->
              <div class="interfaceDiv">
                <div class="interfaceTitle" @click="clickTab(interfaceArr_2.id)">{{interfaceArr_2.name}}</div>
                <div v-for="num in lengthArr_2">
                  <div class="interfaceTab" @click="clickToTab(interfaceArr_2.id,interfaceArr_2.childCategoriesVOList[num-1].name)">{{interfaceArr_2.childCategoriesVOList[num-1].name}}</div>
                </div>
                <div v-for="num in (maxLength-lengthArr_2)">
                  <div class="interfaceTab_2"></div>
                </div>
              </div>
              <div class="interfaceTab_1">
                <div style="cursor: pointer;" @click="openSite(6)">更多蓝牙产品>></div>
              </div>
            </div>
            <div class="interfaceTop">
                <!-- <img style="height: 100px;margin-bottom: 20px;" src="../../assets/image/CH397.png" alt=""/> -->
              <div class="interfaceDiv">
                <div class="interfaceTitle" @click="clickTab(interfaceArr_3.id)">{{interfaceArr_3.name}}</div>
                <div v-for="num in lengthArr_3">
                  <div class="interfaceTab" @click="clickToTab(interfaceArr_3.id,interfaceArr_3.childCategoriesVOList[num-1].name)">{{interfaceArr_3.childCategoriesVOList[num-1].name}}</div>
                </div>
                <div v-for="num in (maxLength-lengthArr_3)">
                  <div class="interfaceTab_2"></div>
                </div>
              </div>
              <div class="interfaceTab_2"></div>
            </div>
            <div class="interfaceTop">
              <!-- <img style="height: 100px;margin-bottom: 20px;" src="../../assets/image/CH483.png" alt=""/> -->
              <div class="interfaceDiv">
                <div class="interfaceTitle" @click="clickTab(interfaceArr_4.id)">{{interfaceArr_4.name}}</div>
                <div v-for="num in lengthArr_4">
                  <div class="interfaceTab" @click="clickToTab(interfaceArr_4.id,interfaceArr_4.childCategoriesVOList[num-1].name)">{{interfaceArr_4.childCategoriesVOList[num-1].name}}</div>
                </div>
                <div v-for="num in (maxLength-lengthArr_4)">
                  <div class="interfaceTab_2"></div>
                </div>
              </div>
              <div class="interfaceTab_2"></div>
          </div>
        </div>
        <div v-else style="margin-top: 20px;">
          <el-collapse accordion>
            <el-collapse-item :title="interfaceArr_1.name" name="1">
              <div v-for="num in lengthArr_1">
                <div class="interfaceTab_3" @click="clickToTab(interfaceArr_1.id,interfaceArr_1.childCategoriesVOList[num-1].name)">
                  {{interfaceArr_1.childCategoriesVOList[num-1].name}}
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div class="interfaceTab_1" >
                <div style="cursor: pointer;" @click="openSite(5)">更多USB产品>></div>
              </div>
            </el-collapse-item>
            <el-collapse-item :title="interfaceArr_2.name" name="2">
              <div v-for="num in lengthArr_2">
                <div class="interfaceTab_3" @click="clickToTab(interfaceArr_2.id,interfaceArr_2.childCategoriesVOList[num-1].name)">
                  {{interfaceArr_2.childCategoriesVOList[num-1].name}}
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div v-if="!isMobileFlag" class="interfaceTab_1">
                <div style="cursor: pointer;" @click="openSite(6)">更多蓝牙产品>></div>
              </div>
            </el-collapse-item>
            <el-collapse-item :title="interfaceArr_3.name" name="3">
              <div v-for="num in lengthArr_3">
                <div class="interfaceTab_3" @click="clickToTab(interfaceArr_3.id,interfaceArr_3.childCategoriesVOList[num-1].name)">
                  {{interfaceArr_3.childCategoriesVOList[num-1].name}}
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :title="interfaceArr_4.name" name="4">
              <div v-for="num in lengthArr_4">
                <div class="interfaceTab_3" @click="clickToTab(interfaceArr_4.id,interfaceArr_4.childCategoriesVOList[num-1].name)">
                  {{interfaceArr_4.childCategoriesVOList[num-1].name}}
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="lineup module" >
        <div id="interface_1" class="lineupTitle moduleTitle">
          <div>MCU/SoC</div>
          <div v-if="isMobileFlag" style="cursor: pointer;font-size: 14px;color: #0049bf;font-weight: 500;line-height: 29px;" @click="openSite(8)">更多MCU产品>></div>
        </div>
        <div class="lineupContent moduleContent" style="font-size:16px;">
          沁恒在连接技术与微处理器领域耕耘多年，基于32位青稞RISC-V、8位等多层次内核与高速USB、Type-C PD、以太网、低功耗蓝牙等专业外设两类关键IP组件的适配和矩阵组合，构建了丰富的MCU/SoC品类，产品覆盖高性能32位微控制器至轻应用8位微控制器等不同定位，侧重于连接、联网和控制，在性能、功耗、连接能力等方面表现出色。自主IP组件模式面向未来，有利于建立长期边际成本优势和地基牢靠、由芯到核的可持续竞争力。
        </div>
        <div style="display:flex; justify-content:center;">
          <div :style="{width:isMobileFlag?'100%':(isOver2k?'85%':'95%'), height:isMobileFlag?'100%':(isOver2k?'85%':'95%')}">
            <line-up ref="lineUp" :categoryId="5"></line-up>
          </div>
        </div>
        <div style="display:flex; justify-content:center;">
          <div v-if="!isMobileFlag" class="interfaceTab_1" :style="{width:isOver2k?'85%':'95%'}">
            <div style="cursor: pointer;" @click="openSite(8)">更多MCU产品>></div>
          </div>
        </div>
      </div>
      <div class="lineup module">
        <div class="lineupTitle moduleTitle" style="margin-bottom: 15px;">应用领域</div>
        <div v-if="!isMobileFlag" class="row">
          <div class="col" @click="openSite(7)">
            <img style="width: 100%; height:100%;" src="../../assets/image/Application_1.png" alt=""/>
          </div>
        </div>
        <div v-else class="row_1">
          <div ref="imgDiv" class="col" @click="openSite(7)">
            <el-carousel :interval="5000" arrow="always" :height="screenWidth">
              <el-carousel-item>
                <img style="width: 100%; height:100%;" src="../../assets/image/Application_2.png" alt=""/>
              </el-carousel-item>
              <el-carousel-item>
                <img style="width: 100%; height:100%;" src="../../assets/image/Application_3.png" alt=""/>
              </el-carousel-item>
              <el-carousel-item>
                <img style="width: 100%; height:100%;" src="../../assets/image/Application_4.png" alt=""/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import {getAside } from '@/services/product.js'
import lineUp from './lineUp/index.vue'
export default {
  name: "interfaces",
  components:{
    lineUp,
  },
  data() {
    return {
      loading:false,
      url:{
        interfaceUrl:'/api/official/website/categories/sidebar',
      },
      interfaceArr_1:'',
      interfaceArr_2:'',
      interfaceArr_3:'',
      interfaceArr_4:'',
      lengthArr_1:0,
      lengthArr_2:0,
      lengthArr_3:0,
      lengthArr_4:0,
      maxLength:8,
      categoryId:"",
      isMobileFlag:false,
      isOver2k:false,
      changFlag:false,
      windowWidth: window.innerWidth,
      activeNames:['1'],
      screenWidth: '',
    };
  },
  computed: {
    
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    if (this.isMobileFlag) {
      this.$nextTick(() => {
        this.screenWidth = this.$refs.imgDiv.offsetWidth*0.51 + 'px';
      });
    }
  },
  watch: {
    $route(to, from) {// 对路由参数变化作出响应，进行锚点定位
        if(to.name =='Products' ){//排除其他页面
          this.categoryId = to.query.categoryId
          this.setPageScrollTop()//设置锚点定位
          this.getInterface()
        }
    },
    windowWidth(newWidth) {
      if (newWidth <= 1200) {
        this.changFlag = true;
      }else{
        this.changFlag = false;
      }
      if (newWidth < 768) {
        this.isMobileFlag = true;
      }else{
        this.isMobileFlag = false;
      }
      if (this.isMobileFlag) {
        this.$nextTick(() => {
          this.screenWidth = this.$refs.imgDiv.offsetWidth*0.51 + 'px';
        });
      }
      if(newWidth>=2500){
        this.isOver2k = true
      }else{
        this.isOver2k = false
      }
    }
  },
  created() {
    if (window.innerWidth < 768) {
      this.isMobileFlag = true;
    } 
    if (window.innerWidth <= 1200) {
      this.changFlag = true;
    } 
    if(window.innerWidth >= 2500){
      this.isOver2k = true
    }
    window.addEventListener('resize', this.handleResize)
  },
  activated(){
    console.log('产品中心的路由query---',this.$route.query)
    if(this.$route.query.categoryId){
      this.categoryId = this.$route.query.categoryId
      this.$emit('giveCategoryId',this.categoryId)//将当前激活侧边菜单传递给父组件
      this.setPageScrollTop()//设置锚点定位
    }
    this.getInterface()
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    setPageScrollTop(){
      if (this.categoryId == '5') {
        setTimeout(() => {
          //document.querySelector('#interface').scrollIntoView({behavior: "smooth",block: "start"});
          const Interface = document.querySelector('#interface_1');
          const offsetTop = Interface.offsetTop;
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });
        }, 10);
        document.title = 'MCU'+' - 南京沁恒微电子股份有限公司'
        
      }else{
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }, 1);
        document.title = '接口芯片'+' - 南京沁恒微电子股份有限公司'
      }
    },
    openLink(){
      window.open('https://www.wch.cn')
    },
    openSite(index){
      if (index == 1) {
        window.open('https://www.wch.cn/application/444.html')
      }
      if (index == 2) {
        window.open('https://www.wch.cn/application/574.html')
      }
      if (index == 3) {
        window.open('https://www.wch.cn/application/461.html')
      }
      if (index == 4) {
        window.open('https://www.wch.cn/application/597.html')
      }
      if (index == 4) {
        window.open('https://www.wch.cn/application/597.html')
      }
      if (index == 5) {
        window.open('https://special.wch.cn/zh_cn/USBChips/')
      }
      if (index == 6) {
        window.open('https://special.wch.cn/zh_cn/BLEChip_Module_Index/')
      }
      if (index == 7) {
        window.open('https://www.wch.cn/application.html/')
      }
      if (index == 8) {
        window.open('https://special.wch.cn/zh_cn/mcu/')
      }
    },
    getInterface(){
      getAside().then(res => {
        this.interfaceArr_1 = res.data.data[0].childCategoriesVOList[0]
        this.lengthArr_1 = res.data.data[0].childCategoriesVOList[0].childCategoriesVOList.length
        this.interfaceArr_2 = res.data.data[0].childCategoriesVOList[1]
        this.lengthArr_2 = res.data.data[0].childCategoriesVOList[1].childCategoriesVOList.length
        this.interfaceArr_3 = res.data.data[0].childCategoriesVOList[2]
        this.lengthArr_3 = res.data.data[0].childCategoriesVOList[2].childCategoriesVOList.length
        this.interfaceArr_4 = res.data.data[0].childCategoriesVOList[3]
        this.lengthArr_4 = res.data.data[0].childCategoriesVOList[3].childCategoriesVOList.length
        let length = 0
        for (let i = 0; i < res.data.data[0].childCategoriesVOList.length; i++) {
          if(res.data.data[0].childCategoriesVOList[i].childCategoriesVOList.length > length){
            length = res.data.data[0].childCategoriesVOList[i].childCategoriesVOList.length 
          }
        }
      });
    },
    clickTab(id){
      if (id == 69) {
        this.$router.push({ path:'/products/productsCenter/otherChip', query: {categoryId:id}})
      } else {
        this.$router.push({ path:'/products/productsCenter/mcuInterface', query: {categoryId:id}})
      }
    },
    clickToTab(id,tName){
      if (id == 69) {
        this.$router.push({ path:'/products/productsCenter/otherChip', query: {categoryId:id,tName:tName.replace(/\&/g,'_')}})
      } else {
        this.$router.push({ path:'/products/productsCenter/mcuInterface', query: {categoryId:id,tName:tName.replace(/\&/g,'_')}})
      }
    }
  }
};
</script>
<style scoped>
 .interfaceContainer{
    width: 100%;
    text-align: left;
 }
 .bread{
    display: flex;
    font-size: 18px;
 }
 .breadLink{
    color: #0049bf;
    cursor: pointer;
 }
.main .titleMsg{
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}
::-webkit-scrollbar {
  display: none;
}
.interfaceMsg{
  display: flex;
  justify-content: space-between;
}
.programMsg{
  display: flex;
  justify-content: space-between;
  background-color: #deebf7;
  margin-top: 40px;
}
.main .titleMsg .titleDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 18%;
  background-color: #deebf7;
  font-weight: 600;
  color: #002c85;
  border-radius: 5px;
  min-width: 150px;
  margin-right: 10px;
}
.main .titleMsg .titleDiv .titleMin{
  width: 90%;
  border-bottom: 4px solid #002c85;
  height: calc(100% - 4px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.programDiv{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  padding: 20px;
  cursor: pointer;
}
#interface_1{
  display: flex;
  justify-content: space-between;
}
.interfaceTop{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 18%;
  padding: 10px;
  padding-bottom: 0px;
}
.interfaceTab{
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
}
.interfaceTab:hover{
  background-color: #fff;
}
.interfaceTab1{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  cursor: pointer;
}
.interfaceTab1:hover{
  background-color: #fff;
}
.interfaceTab_2{
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.interfaceTab_3{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
  border-bottom:1px solid #EBEEF5;
}
.interfaceTab_1{
  width: 100%;
  height: 30px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: flex-end;
  color: #0049bf;
}
.interfaceDiv{
  width: 100%;
  background-color: #deebf7;
  color: #333;
  border-radius: 10px;
  padding: 10px;
}
.interfaceTitle{
  color: #0049bf;
  font-size: 18px;
  font-weight: 600;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.interfaceTitle:hover{
  background-color: #fff;
}
 .main .title{
    font-size: 26px;
    color: #002c85;
    font-weight: 600;
    margin: 30px 0px 10px 0px;
    letter-spacing: 0px;
 }
 .lineup{
    margin-top: 10px;
    margin-bottom: 20px;
 }
 .main .moduleTitle{
    font-size: 22px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 7px;
 }
 .main .moduleContent{
    color:#000;
    letter-spacing: 0px;
    line-height: 1.5;
 }
 .main .lineupContent{
    display: flex;
    justify-content: center;
 }
 #interface{
  height: 40px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .el-collapse /deep/ .el-collapse-item__header{
    font-size: 16px;
    font-weight: 600;
    height: 38px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
  }
  .el-collapse /deep/ .el-collapse-item__content {
    font-size: 14px;
  }
  .el-collapse /deep/ .el-collapse-item__header.is-active{
    border-bottom:1px solid #EBEEF5;
  }
  .row{
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
  }
  .row .col{
    cursor: pointer;
    transition: all 0.3s;
  }
  .row .col:hover{
    transform: scale(1.01);
  }
  .row_1{
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
  }
/*   @media(max-width: 768px) {
    .el-carousel /deep/ .el-carousel__container{
      height: 230px;
    }
  }
  @media(max-width: 579px) {
    .el-carousel /deep/ .el-carousel__container{
      height: 235px;
    }
  }
  @media(max-width: 390px) {
    .el-carousel /deep/ .el-carousel__container{
      height: 155px;
    }
  } */
  .el-carousel /deep/ .el-carousel__indicators--horizontal{
    bottom: -5px;
    min-width: 115px;
  }
  .el-carousel--horizontal{
    overflow-y: hidden;
  }
  .row_1 img{
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
  }
  /* .row_1 img:hover{
    transform: scale(1.01);
  } */
  .schemeItem1{
    background: url('../../assets/image/Program_1.png') no-repeat;
    background-size:cover;
  }
  .schemeItem2{
    background: url('../../assets/image/Program_2.png') no-repeat;
    background-size:cover;
  }
  .schemeItem3{
    background: url('../../assets/image/Program_3.png') no-repeat;
    background-size:cover;
  }
  .schemeItem4{
    background: url('../../assets/image/Program_4.png') no-repeat;
    background-size:cover;
  }
 .el-collapse /deep/ .el-collapse-item__content{
  padding-bottom: 0px;
 }
 .el-collapse /deep/ .el-collapse-item__header{
  color: #002c85;
 }

 @media (max-width: 800px) {
  .main .titleMsg .titleDiv  .titleMin{
    font-size: 14px;
  }
}
@media (min-width: 800px) {
  .main .titleMsg .titleDiv  .titleMin{
    font-size: 14px;
  }
}


@media (min-width: 1200px) {
  .interfaceTab {
    font-size: 12px;
    height: 25px;
  }
  .interfaceTab1{
    font-size: 12px;
    height: 25px;
  }
  .interfaceTab_2{
    height: 25px;
  }
  .main .titleMsg .titleDiv  .titleMin{
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .interfaceTab {
    font-size: 14px;
    height: 30px;
  }
  .interfaceTab1{
    font-size: 14px;
    height: 30px;
  }
  .interfaceTab_2{
    height: 30px;
  }
  .main .titleMsg .titleDiv  .titleMin{
    font-size: 15px;
  }
}

@media (min-width: 1670px) {
  .interfaceTab {
    font-size: 16px;
    height: 36px;
  }
  .interfaceTab1{
    font-size: 16px;
    height: 36px;
  }
  .interfaceTab_2{
    height: 36px;
  }
  .main .titleMsg .titleDiv  .titleMin{
    font-size: 16px;
  }
}

@media (min-width: 1750px) {
  .interfaceTab {
    font-size: 16px;
    height: 36px;
  }
  .interfaceTab1{
    font-size: 16px;
    height: 36px;
  }
  .interfaceTab_2{
    height: 36px;
  }
  .main .titleMsg .titleDiv .titleMin{
    font-size: 18px;
  }
}

</style>